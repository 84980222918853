import PageListing from '../components/PageListing'
import { graphql } from 'gatsby'

export default PageListing

export const query = graphql`
  query PageListing($slug: String!, $categories: [String!]) {
    site {
      siteMetadata {
        title
      }
    }
    listing: mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        categories
        city
        icon {
          childImageSharp {
            gatsbyImageData(height: 500, layout: CONSTRAINED)
          }
        }
        image {
          childImageSharp {
            gatsbyImageData(height: 500, layout: CONSTRAINED)
          }
        }
        image2 {
          childImageSharp {
            gatsbyImageData(height: 500, layout: CONSTRAINED)
          }
        }
        image3 {
          childImageSharp {
            gatsbyImageData(height: 500, layout: CONSTRAINED)
          }
        }
        image4 {
          childImageSharp {
            gatsbyImageData(height: 500, layout: CONSTRAINED)
          }
        }
        image5 {
          childImageSharp {
            gatsbyImageData(height: 500, layout: CONSTRAINED)
          }
        }
        email
        id
        meta {
          title
        }
        phone
        postTitle
        profileData {
          accomodationType
          neighborhood
          neighborhoodDescription
          sitterDescription
          sitterDisplayName
          thingsToKnow
        }
        state
        streetAddress
        tags
        _geoloc {
          lat
          lng
        }
      }
    }
    listings: allMdx(
      limit: 50
      filter: {
        fields: {
          sourceName: { eq: "listings" }
          categories: { in: $categories }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            categories
            city
            id
            image {
              childImageSharp {
                gatsbyImageData(width: 250, layout: CONSTRAINED)
              }
            }
            streetAddress
            postTitle
            tags
          }
        }
      }
    }
  }
`
